.searchbar {
  height: 57px;
  width: 400px;
  margin: 20px;
  border-radius: 100px;
  border: 2px solid #4cc0ad;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5px;
  transition: 200ms all;
}

.searchbar_error {
  border: 2px solid #ea3546;
}

.searchbar_input {
  border: none;
  font-size: 18px;
  font-weight: 300;
  color: #fff;
  background-color: transparent;
  flex-grow: 1;
  margin-left: 2rem;
}

.searchbar_input:focus {
  outline: none;
  border: none;
}

.searchbar_input::placeholder {
  color: #fff;
  opacity: 60%;
}

.search_icon {
  margin: 0px 0px 0px 0px;
}

.menu_item {
  font-size: 2rem;
  color: black;
  font-family: 'Gotham';
}
