.logobox {
  width: 18rem;
}

.title_box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.box:first-child > span {
  margin-right: auto;
}

.box:last-child > span {
  margin-left: auto;
}

.header {
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 5px 10px 14px rgba(0, 0, 0, 0.2);
  position: relative;
}

.h3 {
  font-size: 3.2rem;
  font-family: 'Gotham';
  font-weight: 500;
}

.submitButton {
  font-size: 100%;
}
