.main_content {
  margin: 0 auto;
  width: 100vw;
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.search_row {
  display: flex;
  align-items: center;
  width: 60vw;
  min-width: 80rem;
  justify-content: center;
  position: relative;
}

.searchbar {
  margin: 0 2rem;
}

.grid_container {
  width: 85vw;
  min-width: 80rem;
  height: 70rem;
  margin-bottom: 2rem;
  font-size: 100%;
}

.showing_latest_row {
  margin-bottom: 2rem;
}
