* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  overflow-x: hidden;
}

body {
  background-color: rgba(37, 54, 70, 1);
  color: #ffffff;
  font-family: 'Gotham';
  font-size: 1.6rem;
}

@font-face {
  font-family: 'Gotham';

  src: url('assets/fonts/Gotham-Book.woff') format('truetype'),
    /* Safari, Android, iOS */ url('assets/fonts/Gotham-Book.woff')
      format('woff'),
    /* Modern Browsers */ url('assets/fonts/Gotham-Book.woff2') format('woff2');
  /* Modern Browsers */
  font-weight: 400;
}

@font-face {
  font-family: 'Gotham';

  src: url('assets/fonts/Gotham-Medium.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('assets/fonts/Gotham-Medium.woff')
      format('woff'),
    /* Modern Browsers */ url('assets/fonts/Gotham-Medium.woff2')
      format('woff2');
  /* Modern Browsers */
  font-weight: 500;
}

@font-face {
  font-family: 'Gotham';

  src: url('assets/fonts/Gotham-Light.otf') format('opentype');
  /* Modern Browsers */
  font-weight: 200;
}

@font-face {
  font-family: 'Gotham';

  src: url('assets/fonts/Gotham-Extra-Light-Italic.otf') format('opentype');
  /* Modern Browsers */
  font-weight: 100;
}

@font-face {
  font-family: 'Gotham';

  src: url('assets/fonts/Gotham-Thin-Italic.otf') format('opentype');
  /* Modern Browsers */
  font-weight: 50;
}

::-webkit-scrollbar,
::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track,
::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb {
  background-color: #425563;
  border-radius: 100px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover,
::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}
