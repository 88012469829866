.input {
  height: 40px;
  border-radius: 100px;
  background-color: transparent;
  color: white;
  border: 1px solid #4cc0ad;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  transition: 200ms all;
}

.input:focus {
  outline: none;
  border: 1px solid #4cc0ad;
}

.input::placeholder {
  color: #fff;
  opacity: 60%;
}

.menu_item {
  font-size: 2rem;
  color: black;
  font-family: 'Gotham';
}

.submission_input_error {
  border: 2px solid #ea3546;
}
