.custom_datagrid {
  color: #fff;
  font-family: 'Gotham';
  font-size: 14px;
}

.download_header {
  height: 40px;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.05);
  transform: translate(-50%, -50%);
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.no_submissions {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.spinner_box {
  height: 37.88px;
  width: 100%;
  padding-left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(2.5px, 0px);
}
